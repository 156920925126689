/* eslint-disable @typescript-eslint/naming-convention */
import { Component, SecurityContext, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { AuthUser } from '../../auth.models';
import { LocalStorageKey } from '../../../shared/constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { environment } from '../../../../environments/environment';
import { RegexEnum } from '../../../shared/model/enum/RegexPattern';
import { DomSanitizer } from '@angular/platform-browser';
import { UserType } from '../../../shared/types/auth.types';

export enum OnboardingScreens {
  SignUp = 'signUp',
  Password = 'password',
  VerifyOtp = 'VerifyOtp',
  Preparing = 'preparingAccount',
}

@Component({
  selector: 'rw-self-onboarding',
  templateUrl: './self-onboarding.component.html',
  styleUrls: ['./self-onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe
export class SelfOnboardingComponent implements AutoUnsubscribeI {
  subscriptionRefs;

  OnboardingScreens = OnboardingScreens;

  activeIndex = 0;

  readonly currentScreens = [
    OnboardingScreens.SignUp,
    OnboardingScreens.Password,
    OnboardingScreens.VerifyOtp,
    OnboardingScreens.Preparing,
  ];

  signUpForm: UntypedFormGroup;

  passwordForm: UntypedFormGroup;

  user: AuthUser;

  isShowSpinner = false;

  isShowInternalSpinner = false;

  errMessage: string;

  smsOtpFeatureFlag = false;

  changeLang: string;

  lang: string;

  destroyed = false;

  referralCode = '';

  invalidLicense = null;

  openLicenseKeyDialogVisible = false;

  countries: any[] = [];

  filterValue = '';

  passwordModel: string;

  showLicenseKey = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public translate: TranslateService,
    protected activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private sanitizer: DomSanitizer,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }
    this.createForm();
    this.checkForVerifyOtpFlow();
  }

  get email(): AbstractControl | null {
    return this.signUpForm.get('email');
  }

  get phone(): AbstractControl | null {
    return this.signUpForm.get('phone');
  }

  get selectedCountry(): AbstractControl | null {
    return this.signUpForm.get('selectedCountry');
  }

  private getRegisterDataFromLocalStorage(): object {
    const registerData = this.localStorageService.getItem<any>(
      LocalStorageKey.PreviousRegisterData,
    );

    if (!registerData) {
      return null;
    }

    if (new Date().getTime() > registerData.expiryTime) {
      this.localStorageService.removeItem(LocalStorageKey.PreviousRegisterData);
      return null;
    }

    delete registerData.expiryTime;

    return registerData;
  }

  checkForVerifyOtpFlow(): void {
    const { queryParams } = this.activatedRoute.snapshot;
    if (!Object.keys(queryParams).includes('email')) return;

    let registerData = this.router.getCurrentNavigation()?.extras?.state;
    if (!registerData) {
      registerData = this.getRegisterDataFromLocalStorage();
      if (!registerData) {
        this.router.navigate([], { replaceUrl: true }).then(() => {
          window.location.reload();
        });
      }
    }

    let type = this.sanitizer.sanitize(SecurityContext.HTML, queryParams.type);
    let countryCode = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.cc,
    );
    let phone = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.phone,
    );

    if (type === UserType.GccWithoutPh && phone.startsWith('+966')) {
      countryCode = '966';
      phone = phone.slice(4);
      type = UserType.Gcc;
    }

    this.signUpForm.patchValue({
      email: this.sanitizer.sanitize(SecurityContext.HTML, queryParams.email),
      selectedCountry: {
        countryKey: type === UserType.Gcc ? 'SA' : '',
        code: countryCode,
      },
      phone,
      companyName: registerData.companyName,
      licenseKey: registerData.licenseKey,
    });

    this.passwordForm.patchValue({
      password: registerData.password,
    });

    this.goToPage(OnboardingScreens.VerifyOtp);
  }

  private goToPage(page: OnboardingScreens): void {
    this.activeIndex = this.currentScreens.indexOf(page);
  }

  createForm(): void {
    const emailRegex = environment.allowAliasedEmails
      ? RegexEnum.laxEmail
      : RegexEnum.email;

    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      companyName: ['', [Validators.required]],
      phone: [''],
      utmParams: [''],
      referralCode: [''],
      licenseKey: ['', [Validators.minLength(20), Validators.maxLength(20)]],
      isTermsAndPrivacyAccepted: [false, [Validators.required]],
      selectedCountry: [null],
    });

    this.passwordForm = this.formBuilder.group({
      password: [''],
      showPassword: [''],
    });
  }

  get activeScreen(): OnboardingScreens {
    return this.currentScreens[this.activeIndex];
  }

  handleSignUpFormEvent(signUpFormEvent: UntypedFormGroup): void {
    this.signUpForm = signUpFormEvent;
  }

  nextPage(): void {
    this.activeIndex += 1;
  }

  prevPage(): void {
    this.activeIndex -= 1;
  }
}
