<rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner>
<div class="container flex flex-column" [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
  <div class="grid grid-nogutter justify-content-center w-full">
    <div class="col-8">
      <div class="flex align-items-center justify-content-between py-5">
        <div>
          <img src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="" height="36">
        </div>
        <div class="flex align-items-center gap-2">
          <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
      </div>
      </div>
    </div>

    <rw-sign-up
      class="col-5"
      [ngClass]="{'hidden': activeScreen !== OnboardingScreens.SignUp}"
      [signUpForm]="signUpForm"
      (signUpFormEmitter)="handleSignUpFormEvent($event)"
      (completion)="nextPage()"
    ></rw-sign-up>

    <rw-create-password
      class="col-5"
      [ngClass]="{'hidden': activeScreen !== OnboardingScreens.Password}"
      [signUpForm]="signUpForm"
      [passwordForm]="passwordForm"
      [activatedRoute]="activatedRoute"
      (editInfo)="prevPage()"
      (completion)="nextPage()"
    ></rw-create-password>

    <rw-verify-otp
      class="col-5"
      [ngClass]="{'hidden': activeScreen !== OnboardingScreens.VerifyOtp}"
      [activeScreen]="activeScreen"
      [signUpForm]="signUpForm"
      [passwordForm]="passwordForm"
      (completion)="nextPage()"
    ></rw-verify-otp>

    <rw-preparing-account
      class="col-5"
      [ngClass]="{'hidden': activeScreen !== OnboardingScreens.Preparing}"
    ></rw-preparing-account>
  </div>
</div>