<ng-template #errorTemplate let-message="message" let-dirty="dirty" let-error="error">
    <li 
        class="flex align-items-center gap-2"
        [ngClass]="{
            'text-primary': !error && dirty, 
            'text-danger': error && dirty,
            'text-grey-50': !dirty
        }"
    >
        <i 
            *ngIf="!error && dirty" 
            class="text-base fa-solid fa-circle-check">
        </i>
        <i 
            *ngIf="error && dirty" 
            class="text-base fa-solid fa-circle-exclamation">
        </i>
        <i 
            *ngIf="!dirty" 
            class="text-base fa-regular fa-circle">
        </i>
        <span>{{ 'auth.passwordValidation.password' + message | translate }}</span>
    </li>
</ng-template>

<div class="flex flex-column gap-4 p-5">
    <div class="text-center">
        <img src="../../../../assets/icons/create-password.svg" width="160">
    </div>
    <div class="flex flex-column gap-2">
        <label class="font-bold text-2xl mb-0">{{ 'auth.createPasswordTitle' | translate }}</label>
    </div>
    <div class="flex flex-column gap-3">
        <form class="flex flex-column gap-3" [formGroup]="passwordForm" #ngForm (ngSubmit)="register()">
            <!-- Enter Password - Start -->
            <div class="flex flex-column gap-3">
                <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
                    <i class="fa-regular fa-lock"></i>
                    <p-password [formControl]="password" placeholder="{{ 'auth.enterPassword' | translate }}" [(ngModel)]="passwordModel" 
                        [toggleMask]="true" [feedback]="false" [showClear]="true" styleClass="w-full" required></p-password>
                </span>
                <ul class="flex flex-column text-sm p-0 m-0 gap-2">
                    <ng-container
                        [ngTemplateOutlet]="errorTemplate" 
                        [ngTemplateOutletContext]="{ 
                            message: 'Length',
                            dirty: minLengthErrorHistory,
                            error: minLengthError,
                        }">
                    </ng-container>
                    <ng-container
                        [ngTemplateOutlet]="errorTemplate" 
                        [ngTemplateOutletContext]="{ 
                            message: 'Case',
                            dirty: caseErrorHistory,
                            error: caseError,
                        }">
                    </ng-container>
                    <ng-container
                        [ngTemplateOutlet]="errorTemplate" 
                        [ngTemplateOutletContext]="{ 
                            message: 'Number',
                            dirty: numberErrorHistory,
                            error: numberError,
                        }">
                    </ng-container>
                    <ng-container
                        [ngTemplateOutlet]="errorTemplate" 
                        [ngTemplateOutletContext]="{ 
                            message: 'Symbol',
                            dirty: symbolErrorHistory,
                            error: symbolError,
                        }">
                    </ng-container>
                </ul>
            </div>
            <!-- Enter Password - End -->

            <!-- Submit - Start -->
            <div class="mt-2 gap-2">
                <button
                    pButton pRipple
                    type="submit"
                    class="flex align-items-center justify-content-center w-full"
                    [disabled]="isShowSpinner || isShowInternalSpinner || passwordForm.invalid">
                    <p-progressSpinner *ngIf="isShowInternalSpinner" [style]="{ width: '24px', height: '24px' }" 
                        styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner>
                    <span class="text-base mr-2">{{ 'Next' | translate }}</span>
                    <!-- <i class="fa-regular fa-chevron-right m-0"></i> -->
                </button>
            </div>
            <!-- Submit - End -->
        </form>
        <!-- Submit - Start -->
        <div class="gap-2">
            <button
                pButton pRipple
                class="flex align-items-center justify-content-center w-full p-button-text p-button-plain"
                (click)="editInfoEmit()"
            >
                <i 
                    class="fa-regular rw__me-3"
                    [ngClass]="{
                        'fa-chevron-left': lang==='en',
                        'fa-chevron-right': lang==='ar',
                    }"
                ></i>
                <span>{{ 'auth.goBack' | translate }}</span>
            </button>
        </div>
        <!-- Submit - End -->
    </div>
</div>