<!-- smsOtpFeatureFlag -->
<form *ngIf="smsOtpFeatureFlag"
  [formGroup]="newPasswordFormGroup"
  #ngForm="ngForm">
  <div class="flex flex-column gap-4 py-5">
    <div class="text-center">
        <img src="../../../../assets/icons/create-password.svg" width="160">
    </div>
    <div class="flex flex-column gap-2">
        <label class="font-bold text-2xl mb-0">{{'Change your Password' | translate}} {{userPhone}}</label>
        <p class="font-normal text-lg mb-0" [innerHTML]="message | translate : {medium: medium}"></p>
    </div>
    <div class="flex flex-column gap-4">
      <!-- Warning - Start -->
      <div *ngIf="errMessage" class="border-1 border-red-40 rw__notification-error">
          <div class="notification-body align-items-start">
              <i class="border-3 border-red-30 rounded-circle fa-regular fa-circle-exclamation rw__fs-5"></i>
              <div>
                  <p class="rw__m-0">
                      {{ errMessage | translate }}
                  </p>
              </div>
          </div>
      </div>
      <div *ngIf="(verificationCode.touched && verificationCode.invalid) || newPasswordFormGroup.get('verificationCode').errors?.cannotContainSpace" class="border-1 border-red-40 rw__notification-error">
        <div class="notification-body align-items-start">
            <i class="border-3 border-red-30 rounded-circle fa-regular fa-circle-exclamation rw__fs-5"></i>
            <div>
                <p class="rw__m-0">
                    {{ 'Verification Code is required' | translate }}
                </p>
            </div>
        </div>
      </div>
      <!-- Warning - End -->
      <div class="rw__otp rw__otp-w-full">
        <rw-otp-input [formControl]="otpFormControl" [otpLength]="6" (getOTP)="getOTP($event)" [autoFocusInput]="true"></rw-otp-input>
      </div>
    </div>
    <div class="flex flex-column text-lg gap-1 align-items-start">
      <span *ngIf="timerVisible" [innerHTML]="'resetTimer' | translate : {countDownSeconds: countdownSeconds}"></span>
      <button pButton type="button" class="rw__button p-button-text small_height rw__px-0 bg-transparent" label="{{'auth.resendCodeTitle' | translate}}"
      (click)="resendForgotPasswordCode()" [disabled]="timerVisible"
      ></button>
      <button *ngIf="sendToEmailButtonVisibility" pButton type="button" class="rw__button p-button-text small_height rw__px-0 bg-transparent" label="{{'Send to Email Instead' | translate}}"
      (click)="sendToEmailInstead()" [disabled]="isShowSpinner"
      ></button>
    </div>

    <div>
      <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
        <i class="fa-regular fa-lock"></i>
        <p-password pendo-tracker="npi-1" [formControl]="newPasswordFormGroup.get('password')" [(ngModel)]="passwordModel" placeholder="{{ 'New Password' | translate }}" [toggleMask]="true" [feedback]="false" [showClear]="true" styleClass="w-full" required></p-password>
      </span>
      <ng-container *ngIf="password.invalid && (password.dirty || password.touched)">
        <div class="flex align-items-center mt-1 font-light">
          <i class="fa-solid fa-circle-exclamation p-error"></i>
          <small *ngIf="password.touched && password.errors?.required" class="p-error block mx-1">{{ 'Password is required' | translate }}</small>
          <small *ngIf="password.errors.pattern" class="p-error block mx-1">{{ 'passwordValidation.requirements' | translate }}</small>
        </div>
      </ng-container>
    </div>

    <div>
      <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
        <i class="fa-regular fa-lock"></i>
        <p-password pendo-tracker="npi-2" [formControl]="newPasswordFormGroup.get('pwdConfirm')" [(ngModel)]="confirmPasswordModel" placeholder="{{ 'Confirm Password' | translate }}" [toggleMask]="true" [feedback]="false" [showClear]="true" styleClass="w-full" required></p-password>
      </span>
      <ng-container *ngIf="pwdConfirm.touched && pwdConfirm.errors?.required">
        <div class="flex align-items-center mt-1 font-light">
          <i class="fa-solid fa-circle-exclamation p-error"></i>
          <small class="p-error block mx-1">{{ 'Confirm Password is required' | translate }}</small>
        </div>
      </ng-container>
      <ng-container *ngIf="pwdConfirm.touched && !pwdConfirm.errors?.required && password.value !== pwdConfirm.value">
        <div class="flex align-items-center mt-1 font-light">
          <i class="fa-solid fa-circle-exclamation p-error"></i>
          <small class="p-error block mx-1">{{ 'Those passwords did not match. Try again.' | translate }}</small>
        </div>
      </ng-container>
    </div>

    <!-- Submit - Start -->
    <button
      pendo-tracker="npb-1"
      pButton pRipple
      type="button"
      (click)="setNewPassword(newPasswordFormGroup.value)"
      class="mt-2 flex align-items-center justify-content-center w-full"
      [disabled]="isShowSpinner || password.value !== pwdConfirm.value || !vButtonVisibility || password.invalid"
    >
      <!-- TODO for Dev: Handle the spinner functionality -->
      <!-- <p-progressSpinner [style]="{ width: '24px', height: '24px' }" 
        styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner> -->
      <span class="text-base">{{ 'Change Password' | translate }}</span>
    </button>
    <!-- Submit - End -->

    <!-- Cancel - Start -->
    <div class="gap-2">
      <button pendo-tracker="npb"
        pButton pRipple
        class="flex align-items-center justify-content-center w-full p-button-text p-button-plain"
        [routerLink]="['/login']"
      >
        <i 
            class="fa-regular rw__me-3"
            [ngClass]="{
                'fa-chevron-left': lang==='en',
                'fa-chevron-right': lang==='ar',
            }"
        ></i>
        <span>{{ 'Cancel' | translate }}</span>
      </button>
    </div>
    <!-- Cancel - Start -->
  </div>
</form>

<!-- !smsOtpFeatureFlag -->
<form *ngIf="!smsOtpFeatureFlag"
  [formGroup]="newPasswordFormGroup"
  #ngForm="ngForm">
  <div class="flex flex-column gap-4 py-5">
    <div class="text-center">
        <img src="../../../../assets/icons/create-password.svg" width="160">
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-bold text-2xl mb-0">{{'Change your Password' | translate}}</label>      
      <p class="font-normal text-lg mb-0" *ngIf="userEmail && !errMessage">
        {{ 'Enter the 6-digit code sent to' | translate }}
        <strong>
          {{ userEmail }}
        </strong>
      </p>
    </div>
    <div class="flex flex-column gap-4">
      <!-- Warning - Start -->
      <div *ngIf="pwdConfirm.touched && !pwdConfirm.errors?.required && password.value !== pwdConfirm.value" class="border-1 border-red-40 rw__notification-error">
          <div class="notification-body align-items-center">
              <i class="border-3 border-red-30 rounded-circle fa-regular fa-circle-exclamation rw__fs-5"></i>
              <div>
                  <p class="rw__m-0">
                    {{ 'Those passwords did not match. Try again.' | translate }}
                  </p>
              </div>
          </div>
      </div>
      <!-- Warning - End -->
      <!-- Code Input - Start -->
      <div>
        <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
          <i class="fa-regular fa-lock-hashtag"></i>
          <input pendo-tracker="npi"
            formControlName="verificationCode"
            pInputText
            type="text"
            id="verificationCode" 
            placeholder="{{ 'Confirmation Code' | translate }}"
          />
        </span>
        <ng-container *ngIf="verificationCode.touched && verificationCode.invalid">
          <div class="flex align-items-center mt-1 font-light">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small class="p-error block mx-1">{{ 'Verification Code is required' | translate }}</small>
          </div>
        </ng-container>
        <ng-container *ngIf="errMessage && verificationCode.valid">
          <div class="flex align-items-center mt-1 font-light">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small class="p-error block mx-1">{{ errMessage | translate }}</small>
          </div>
        </ng-container>
      </div>
      <!-- Code Input - End -->
    </div>

    <div>
      <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
        <i class="fa-regular fa-lock"></i>
        <p-password id="password" formControlName="password" placeholder="{{ 'New Password' | translate }}" [toggleMask]="true" [feedback]="false" styleClass="w-full" required></p-password>
      </span>
      <ng-container *ngIf="password.touched && password.errors?.required">
        <div class="flex align-items-center mt-1 font-light">
          <i class="fa-solid fa-circle-exclamation p-error"></i>
          <small class="p-error block mx-1">{{ 'Password is required' | translate }}</small>
        </div>
      </ng-container>
      <ng-container *ngIf="password.invalid && (password.dirty || password.touched)">
        <div *ngIf="password.errors?.pattern" class="flex align-items-center mt-1 font-light">
          <i class="fa-solid fa-circle-exclamation p-error"></i>
          <small class="p-error block mx-1">{{ 'passwordValidation.requirements' | translate }}</small>
        </div>
      </ng-container>
    </div>

    <div>
      <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
        <i class="fa-regular fa-lock"></i>
        <p-password formControlName="pwdConfirm" placeholder="{{ 'Confirm Password' | translate }}" [toggleMask]="true" [feedback]="false" styleClass="w-full" required></p-password>
      </span>
      <ng-container *ngIf="pwdConfirm.touched && pwdConfirm.errors?.required">
        <div class="flex align-items-center mt-1 font-light">
          <i class="fa-solid fa-circle-exclamation p-error"></i>
          <small class="p-error block mx-1">{{ 'Confirm Password is required' | translate }}</small>
        </div>
      </ng-container>
    </div>

    <!-- Submit - Start -->
    <button
      pButton pRipple
      type="button"
      (click)="setNewPassword(newPasswordFormGroup.value)"
      class="mt-2 flex align-items-center justify-content-center w-full"
      [disabled]="
          newPasswordFormGroup.invalid ||
          isShowSpinner ||
          password.value !== pwdConfirm.value
        "
    >
      <!-- TODO for Dev: Handle the spinner functionality -->
      <!-- <p-progressSpinner [style]="{ width: '24px', height: '24px' }" 
        styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner> -->
      <span class="text-base">{{ 'Change Password' | translate }}</span>
    </button>
    <!-- Submit - End -->

    <!-- Cancel - Start -->
    <div class="gap-2">
      <button
        pButton pRipple
        class="flex align-items-center justify-content-center w-full p-button-text p-button-plain"
        [routerLink]="['/login']"
      >
        <i 
            class="fa-regular rw__me-3"
            [ngClass]="{
                'fa-chevron-left': lang==='en',
                'fa-chevron-right': lang==='ar',
            }"
        ></i>
        <span>{{ 'Cancel' | translate }}</span>
      </button>
    </div>
    <!-- Cancel - Start -->
  </div>
</form>