/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageKey } from '../../../../shared/constants';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { LocationService } from '../../../../core/services/location.service';
import { CustomToastService } from '../../../../shared/services/custom-toast.service';

@Component({
  selector: 'rw-update-number-dialog',
  templateUrl: './update-number.component.html',
  styleUrls: ['./update-number.component.scss'],
})
@AutoUnsubscribe
export class UpdatePhoneComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  @Input() numberForm: UntypedFormGroup;

  @Input() dialogVisible: boolean;

  @Output() completion = new EventEmitter<string>();

  isShowSpinner = false;

  isShowInternalSpinner = false;

  changeLang: string;

  lang: string;

  countries: any[] = [];

  selectedCountry: {
    countryKey: 'SA';
    name: 'Saudi Arabia';
    code: '966';
    currency: 'SAR';
    languages: 'ar';
    localeName: 'Saudi Arabia';
  };

  filterValue = '';

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }
  }

  get phone(): AbstractControl | null {
    return this.numberForm.get('phone');
  }

  ngOnInit(): void {
    this.phone.valueChanges.subscribe(() => {
      this.phone.markAsUntouched();
    });

    this.phone.setValidators([
      Validators.required,
      this.getPhoneNumberValidator(),
    ]);
  }

  getPhoneNumberValidator() {
    return (control: FormControl) => {
      const inputValue = control.value;

      if (this.selectedCountry && this.selectedCountry?.countryKey !== 'SA') {
        const pattern = new RegExp('^[0-9]{5,13}$');
        if (!pattern.test(inputValue)) {
          return { otherThanSaudiPattern: true };
        }
      } else {
        if (!control.value.startsWith('05') && !control.value.startsWith('5')) {
          return { saudiZeroOrZeroFive: true };
        }
        if (control.value.startsWith('0')) {
          const pattern = new RegExp('^(05[0-9]{8})$');
          if (!pattern.test(inputValue)) {
            return { zeroSaudiPattern: true };
          }
        } else {
          const pattern = new RegExp('^(5[0-9]{8})$');
          if (!pattern.test(inputValue)) {
            return { saudiPattern: true };
          }
        }
      }

      return null; // Return null if validation passes
    };
  }

  closeThisDialog(): void {
    this.completion.emit('');
  }

  updatePhone(): void {
    if (this.numberForm.invalid) return;

    this.completion.emit(this.phone.value);
    this.phone.reset();
  }
}
