import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { RegisterComponent } from './components/register/register.component';
import { SelfOnboardingComponent } from './components/self-onboarding/self-onboarding.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmRegistrationComponent } from './components/confirm/confirm-registration.component';
import { ResetPasswordComponent } from './components/forget-password/reset-password/reset-password.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { TrialSubscribeUserComponent } from './components/trial-subscribe-user/trial-subscribe-user.component';

// Services
import { UnAuthenticatedGuardService } from './services/unauthenticated-auth.guard.service';
import { LoginV2Component } from './components/login-v2/login-v2.component';
import { ResetPasswordV2Component } from './components/forget-password/reset-password-v2/reset-password-v2.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginV2Component,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'register',
    component: SelfOnboardingComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'reset-password',
    component: ResetPasswordV2Component,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'confirmation',
    component: ConfirmRegistrationComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'activate-user/:activatedCode',
    component: ActivateUserComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
