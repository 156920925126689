<div *ngIf="!shouldShowRetryLimitReachedMessage()" class="flex flex-column gap-4 p-5">
    <div class="text-center">
        <img src="../../../../assets/icons/otp-verification.svg" width="160">
    </div>
    <div class="flex flex-column gap-2">
        <label class="font-bold text-2xl mb-0">{{ 'auth.verifyOtpTitle' | translate }}</label>
        <p class="font-normal text-lg mb-0" [innerHTML]="message | translate : {medium: medium}"></p>
    </div>
    <div class="flex flex-column gap-4">
        <!-- Change Phone - Start -->
        <div *ngIf="smsAvailable" class="text-base text-grey-50">
            {{ 'auth.notYourNumber' | translate }}
            <a (click)="showEditPhoneModal()" class="rw__link cursor-pointer text-teal-60">{{ 'auth.editNumber' | translate }}</a>
        </div>
        <!-- Change Phone - End -->
        <!-- Invalid or Expired OTP Warning - Start -->
        <div *ngIf="errMessage" class="border-1 border-red-40 rw__notification-error">
            <div class="notification-body align-items-start">
                <i class="border-3 border-red-30 rounded-circle fa-regular fa-circle-exclamation rw__fs-5"></i>
                <div>
                    <p class="rw__m-0">
                        {{ 'auth.' + errMessage | translate }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Invalid or Expired OTP Warning - End -->
        <div class="rw__otp rw__otp-w-full">
            <rw-otp-input [formControl]="otpFormControl" [otpLength]="6" (getOTP)="getOTP($event)" [autoFocusInput]="true"></rw-otp-input>
        </div>
    </div>
    <div class="flex flex-column gap-3">
        <div class="flex flex-column gap-3" >
            <div class="flex flex-column text-lg gap-1">
                <span *ngIf="timerVisible" [innerHTML]="'auth.resetTimer' | translate : {countDownSeconds: countdownSeconds}"></span>
                <div class="small_height">
                    <button pButton type="button" class="rw__button p-button-text small_height text-lg rw__px-0 bg-transparent" label="{{'auth.resendCodeTitle' | translate}}"
                        (click)="resendConfirmationV2(email.value, phone)" pendo-tracker="crb" [disabled]="isRetryDisabled()"
                    ></button>
                </div>
                <div class="small_height">
                    <button pButton type="button" pendo-tracker="tg-sms" class="rw__button p-button-text small_height w-auto text-lg rw__px-0 bg-transparent" [disabled]="isRetryDisabledForEmail()"
                        label="{{'auth.changeMediumToEmail' | translate}}" *ngIf="deliveryMedium === deliveryMediumEnum.SMS"
                        (click)="toggleDeliveryMedium()"></button>
                    <button pButton type="button" pendo-tracker="tg-email" class="rw__button p-button-text small_height w-auto text-lg rw__px-0 bg-transparent" [disabled]="isRetryDisabledForSms()"
                        label="{{'auth.changeMediumTomPhone' | translate}}" *ngIf="smsAvailable && deliveryMedium === deliveryMediumEnum.EMAIL"
                        (click)="toggleDeliveryMedium()"></button>
                </div>
            </div>
            <!-- Submit - Start -->
            <div class="mt-2 gap-2">
                <button
                    pButton pRipple
                    (click)="confirm()"
                    class="flex align-items-center justify-content-center w-full"
                    [disabled]="isShowSpinner || isShowInternalSpinner">
                    <p-progressSpinner 
                        *ngIf="isShowInternalSpinner" [style]="{ width: '24px', height: '24px' }" 
                        styleClass="flex rw__spinner rw__me-2" strokeWidth="4"
                    ></p-progressSpinner>
                    <span *ngIf="!isShowInternalSpinner" class="text-base">{{ 'auth.verify' | translate }}</span>
                    <span *ngIf="isShowInternalSpinner" class="text-base">{{ 'auth.verifying' | translate }}</span>
                </button>
            </div>
            <!-- Submit - End -->
        </div>
    </div>
</div>

<!-- OTP Exhaust UI -->
<div *ngIf="shouldShowRetryLimitReachedMessage()" class="flex flex-column gap-4 p-5 otp-exhaust-section">
    <div class="flex justify-content-center mb-4">
        <i class="border-red-20 fa-regular fa-circle-xmark text-danger oes-fig"></i>
    </div>
    <div class="flex flex-column text-center gap-3">
        <label class="font-bold text-xl mb-0">{{ 'auth.exceededLimit' | translate }}</label>
        <p class="font-normal text-lg mb-0">
            {{ 'auth.tryAgainLater' | translate }} 
        </p>
    </div>
    <button
        pButton pRipple
        class="flex align-items-center justify-content-center w-full p-button-text p-button-plain edit-info-back"
        (click)="restartSignUp()"
    >
        <i 
            class="fa-regular rw__me-3"
            [ngClass]="{
                'fa-chevron-left': lang==='en',
                'fa-chevron-right': lang==='ar',
            }"
        ></i>
        <span>{{ 'auth.goBack' | translate }}</span>
    </button>
    <div class="text-center">
        <span class="text-base text-grey-50">
          {{ 'needHelp' | translate }}
          <a class="rw__link cursor-pointer text-teal-60">{{ 'Contact Support' | translate }}</a>
        </span>
      </div>
</div>

<rw-update-number-dialog
    [numberForm]="numberForm"
    [dialogVisible]="editPhoneModalVisible"
    (completion)="updatePhoneNumber($event)"
></rw-update-number-dialog>